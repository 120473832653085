// Note this is a really minimal redux setup for small things,
// Expand and put into folders when it gets bigger

import { createStore as reduxCreateStore } from "redux"

const reducer = (state, action) => {
  if (action.type === `HANDLE_CONTACT_FORM`) {
    return Object.assign({}, state, {
      contactFormOpen: action.openClose,
    })
  }
  return state
}

const initialState = { contactFormOpen: false }

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore